import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { PermissionContext } from '../context/permission-context';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import usePermissions from '../hooks/use-permissions';
import createHocName from '../services/create-hoc-name';
import createPermissionHelpers from '../services/create-permission-helpers';

export type WithPermissions = {
  permissions: string[];
} & ReturnType<typeof createPermissionHelpers>;

/** @deprecated use {@link usePermissions} hook instead */
function withPermissions<T extends WithPermissions = WithPermissions>(
  WrappedComponent: React.ComponentType<T>,
) {
  const WithPermissions = (props: Omit<T, keyof WithPermissions>) => (
    <PermissionContext.Consumer>
      {(permissions) => (
        <WrappedComponent
          // @ts-expect-error
          permissions={permissions}
          {...createPermissionHelpers(permissions)}
          {...(props as T)}
        />
      )}
    </PermissionContext.Consumer>
  );

  WithPermissions.displayName = createHocName(
    'WithPermissions',
    WrappedComponent,
  );

  hoistNonReactStatics(WithPermissions, WrappedComponent);

  return WithPermissions;
}

export default withPermissions;
