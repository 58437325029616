import React, { useCallback, useEffect } from 'react';
import { isSSR } from '@wix/communities-blog-client-common';
import useScrollListener, {
  ScrollListenerHandler,
} from '../../hooks/use-scroll-listener';
import scrollParent from '../../services/scroll-parent';
import {
  isPreview as getIsPreview,
  isSite as getIsSite,
} from '../../store/basic-params/basic-params-selectors';
import { useSelector } from '../runtime-context';

type Props = {
  hasMore?: boolean;
  /** Page from which component starts tracking the current page */
  pageStart?: number;
  threshold?: number;
  loader?: React.ReactNode;
  loadMore?: (page: number) => Promise<any>;
  isLoading?: boolean;
};

const LoadMore: React.FC<Props> = ({
  hasMore = false,
  pageStart = 2,
  threshold = 250,
  children,
  loader,
  isLoading: isLoadingProp,
  loadMore,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = useRefPage(pageStart);
  const { componentId, isSite, isPreview } = useSelector((state, host) => {
    return {
      isSite: getIsSite(state),
      isPreview: getIsPreview(state),
      componentId: host.id,
    };
  });

  const handleLoad = useCallback(() => {
    if (isLoadingProp || isLoading) {
      return;
    }

    setIsLoading(true);

    Promise.resolve(loadMore?.(ref.current.page)).then(() => {
      setIsLoading(false);
    });

    ref.current.page++;
  }, [isLoading, isLoadingProp, ref, loadMore]);

  const shouldLoadMoreInEditor: ScrollListenerHandler = useCallback(
    (scroll) => {
      const element = scrollParent();

      if (!hasMore || isLoadingProp || isLoading || isSSR() || !element) {
        return false;
      }

      const position = scroll.scrollTop + scroll.documentHeight + threshold;
      const end = element.scrollHeight + scroll.y;

      return position >= end;
    },
    [hasMore, isLoadingProp, isLoading, threshold],
  );

  const shouldLoadMore = useCallback(() => {
    if (!hasMore || isLoadingProp || isLoading || isSSR()) {
      return false;
    }

    const component = window.document.getElementById(componentId);

    if (component == null) {
      return false;
    }

    const { height, top } = component.getBoundingClientRect();

    return window.innerHeight + threshold > height + top;
  }, [componentId, hasMore, isLoading, isLoadingProp, threshold]);

  const handleScroll: ScrollListenerHandler = useCallback(
    (scroll) => {
      const shouldHandle =
        isSite || isPreview ? shouldLoadMore() : shouldLoadMoreInEditor(scroll);

      if (shouldHandle) {
        handleLoad();
      }
    },
    [handleLoad, isPreview, isSite, shouldLoadMore, shouldLoadMoreInEditor],
  );

  // Handles cases for large screens where the bottom is already visible
  useEffect(() => {
    const shouldHandle = isSite || isPreview ? shouldLoadMore() : false;

    if (shouldHandle) {
      handleLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollListener(handleScroll);

  return (
    <div>
      {children}
      {hasMore && (isLoadingProp || isLoading) && loader}
    </div>
  );
};

const useRefPage = (pageStart: number) => {
  const ref = React.useRef({ page: pageStart, pageStart });

  // Reset page when pageStart prop changes
  useEffect(() => {
    const pageStartChanged = pageStart !== ref.current.pageStart;

    if (pageStartChanged) {
      ref.current = { page: pageStart, pageStart };
    }
  }, [pageStart]);

  return ref;
};

export default LoadMore;
