import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';

import PaginationArrows from './components/pagination-arrows';
import PaginationPageNumbers from './components/pagination-page-numbers';
import PaginationPosition from './components/pagination-position';
import { defaultPaginationProps, PaginationButtonType } from './constants';
import { useArrowIcons } from './hooks/use-arrow-icons';
import { usePaginationState } from './hooks/use-pagination-state';
import { PaginationProps } from './types';
import styles from './pagination.scss';

const Pagination: React.FC<PaginationProps> = (props) => {
  const state = usePaginationState(props);
  const { t } = useTranslation();
  const ArrowIcons = useArrowIcons();
  const {
    entityCount = defaultPaginationProps.entityCount,
    showPosition = defaultPaginationProps.showPosition,
    component:
      Component = defaultPaginationProps.component as React.ComponentType<any>,
    pageSize = defaultPaginationProps.pageSize,
    arrowFillClass = 'blog-icon-fill',
    activeClass,
    className,
    pageClass,
    positionClass,
  } = props;

  if (entityCount <= pageSize) {
    return null;
  }

  const arrowProps = {
    state,
    showPosition,
    arrowClass: styles.arrow,
    disabledClass: styles.disabled,
    component: Component,
  };

  return (
    <div
      className={classNames(
        styles.container,
        showPosition && styles.withPosition,
        className,
      )}
      aria-live="polite"
    >
      <PaginationArrows
        {...arrowProps}
        label={t('pagination.first-page')}
        type={PaginationButtonType.FIRST}
      >
        <ArrowIcons.Left className={arrowFillClass} />
        <ArrowIcons.Left className={arrowFillClass} />
      </PaginationArrows>
      <PaginationArrows
        {...arrowProps}
        label={t('pagination.previous-page')}
        type={PaginationButtonType.PREVIOUS}
      >
        <ArrowIcons.Left className={arrowFillClass} />
      </PaginationArrows>

      {showPosition ? (
        <PaginationPosition
          state={state}
          className={classNames(styles.position, positionClass)}
        />
      ) : (
        <PaginationPageNumbers
          activeClass={classNames(
            styles.active,
            'blog-button-color',
            activeClass,
          )}
          component={Component}
          innerClass={classNames(styles.page, pageClass)}
          state={state}
        />
      )}

      <PaginationArrows
        {...arrowProps}
        label={t('pagination.next-page')}
        type={PaginationButtonType.NEXT}
      >
        <ArrowIcons.Right className={arrowFillClass} />
      </PaginationArrows>
      <PaginationArrows
        {...arrowProps}
        label={t('pagination.last-page')}
        type={PaginationButtonType.LAST}
      >
        <ArrowIcons.Right className={arrowFillClass} />
        <ArrowIcons.Right className={arrowFillClass} />
      </PaginationArrows>
    </div>
  );
};

export default Pagination;
