import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { LikeIcon } from '../icons/like-icon';
import styles from './like-button.scss';

class LikeButton extends Component {
  state = {
    isAnimationEnabled: false,
  };

  constructor(props) {
    super(props);

    this.handleAnimation = this.handleAnimation.bind(this);
  }

  handleAnimation(event) {
    if (this.props.isDisabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (!this.props.isLiked) {
      this.setState({ isAnimationEnabled: true });
    }
  }

  render() {
    const { isLiked } = this.props;
    const { isAnimationEnabled } = this.state;

    return (
      <div
        onClick={this.handleAnimation}
        className={classNames(
          'like-button',
          styles.heart,
          { [styles.active]: isLiked },
          { [styles.isAnimationEnabled]: isAnimationEnabled },
        )}
        data-hook="like-button"
      >
        <div className={classNames(styles.pos1)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos2)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos3)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.pos4)}>
          <div className={classNames(styles.heartSparkle)} />
        </div>
        <div className={classNames(styles.heartRing)} />
        <LikeIcon className={styles.heartBody} />
      </div>
    );
  }
}

LikeButton.propTypes = {
  isLiked: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default LikeButton;
