import { identity, intersection, map } from 'lodash';

import { Post as PostV3Nullable } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  DeepRequired,
  getCategoryIds,
  removeCorruptedEntities,
} from '@wix/communities-blog-client-common';
import { getTranslations } from '../store/translations/translations-selectors';
import { AppState, NormalizedPost, PostV2, RelatedPostV2 } from '../types';

type PostV3 = DeepRequired<PostV3Nullable>;

type Origin = '/v2/posts/publications' | '/v3/posts';

type NormalizePostsParams = {
  state: any;
  posts: PostV3[] | PostV2[] | undefined;
  blogCategoryIds: string[];
  origin?: Origin;
};

export const normalizePosts = ({
  state,
  posts,
  blogCategoryIds,
  origin,
}: NormalizePostsParams): NormalizedPost[] =>
  posts?.map((post) =>
    normalizePost({ state, post, blogCategoryIds, origin }),
  ) ?? [];

type NormalizePostParams = {
  state: AppState;
  post: RelatedPostV2 | PostV2 | PostV3;
  blogCategoryIds: string[];
  origin?: Origin;
};

export const normalizePost = ({
  state,
  post,
  blogCategoryIds,
  origin,
}: NormalizePostParams): NormalizedPost => {
  const normalizerFn = getPostNormalizerFn(origin);

  const normalized: NormalizedPost = {
    ...normalizerFn(post as any),
    categoryIds: normalizeCategoryIds(post, blogCategoryIds),
  };
  const draft = normalized.draft;

  normalized.content = removeCorruptedEntities(normalized.content);

  if (isDemoUserPost(post, normalized)) {
    normalized.owner.name =
      getTranslations(state)['demo-user.posts-list.owner-name'] || 'Admin';
  }

  if (draft) {
    draft.categoryIds = normalizeCategoryIds(draft, blogCategoryIds);
    draft.content = removeCorruptedEntities(draft.content);
    draft.firstPublishedDate ??=
      'firstPublishedDate' in post ? post.firstPublishedDate : undefined;
  }

  return normalized;
};

export const normalizePostEntities = (entities: any[], state: AppState) =>
  map(entities, (entity) =>
    entity.type === 'post'
      ? normalizePost({
          state,
          post: entity,
          blogCategoryIds: getCategoryIds(state),
        })
      : entity,
  );

export const normalizePostV3 = (post: PostV3): NormalizedPost => {
  const {
    content,
    metrics,
    pinned,
    featured,
    mostRecentContributorId,
    pricingPlanIds,
    minutesToRead,
    commentingEnabled,
    slug,
  } = post;

  return {
    ...post,
    owner: post.owner as NormalizedPost['owner'],
    firstPublishedDate: post.firstPublishedDate as unknown as string,
    lastPublishedDate: post.lastPublishedDate as unknown as string,
    content: typeof content === 'string' ? JSON.parse(content) : content,
    coverImage: normalizePostCoverImage(post),
    likeCount: metrics?.likes || 0,
    viewCount: metrics?.views || 0,
    totalComments: metrics?.comments || 0,
    averageRating: metrics?.averageRating || 0,
    totalRatings: metrics?.totalRatings || 0,
    isFeatured: featured,
    isPinned: pinned,
    isCommentsDisabled: !commentingEnabled,
    lastWriterSiteMemberId: mostRecentContributorId,
    paidPlansGroupIds: pricingPlanIds,
    timeToRead: minutesToRead,
    slugs: [slug as string],
    status: 'published',
    ...makeSeoProps(post),
  };
};

export const getOwnerTitle = (post: PostV2) => post?.owner?.name?.trim();

export const discardDeletedCategoryIds = (
  postCategoryIds: string[],
  blogCategoryIds: string[],
) => intersection(postCategoryIds || [], blogCategoryIds);

const getPostNormalizerFn = (origin: Origin | undefined) => {
  switch (origin) {
    case '/v2/posts/publications':
      return normalizePostPublications;
    case '/v3/posts':
      return normalizePostV3;
    default:
      return identity;
  }
};

const normalizeCategoryIds = (
  post: RelatedPostV2 | PostV2 | PostV3,
  blogCategoryIds: string[],
): string[] =>
  'categories' in post && post.categories?.length
    ? post.categories.map((c) => c.id)
    : discardDeletedCategoryIds(post.categoryIds, blogCategoryIds);

const normalizePostPublications = (post: RelatedPostV2): NormalizedPost => {
  return {
    ...post,
    status: 'published',
    content: undefined,
    isCommentsDisabled: !post.isCommentingEnabled,
    likeCount: post.likesCount,
    viewCount: post.viewsCount,
    totalComments: post.commentsCount,
    averageRating: 0,
    totalRatings: 0,
  };
};

const normalizePostCoverImage = (
  post: PostV3,
): NormalizedPost['coverImage'] => {
  if (!post.coverMedia) {
    return undefined;
  }

  const {
    image,
    video,
    custom: isCustom,
    enabled: isEnabled,
    displayed: shouldRender,
  } = post.coverMedia;
  return {
    isCustom,
    isEnabled,
    isResolved: !isCustom,
    shouldRender,
    src: image?.url || video?.url,
    ...(image && {
      imageMetadata: {
        file_name: image.filename || image.id,
        height: image.height,
        width: image.width,
      },
    }),
    ...(video && {
      videoMetadata: {
        video_url: video.url,
        thumbnail_height: video.thumbnail.height,
        thumbnail_width: video.thumbnail.width,
        thumbnail_url: video.thumbnail.url,
      },
    }),
  };
};

const makeSeoProps = (post: PostV3) => {
  let canonicalUrl: string | undefined;
  let seoTitle: string | undefined;
  let seoDescription: string | undefined;

  let seoShowInSearch = true;
  let seoShowSnippetInSearch = true;

  post.seoData?.tags?.forEach(({ type, props, children }) => {
    switch (type) {
      case 'link':
        if (props?.rel === 'canonical') {
          canonicalUrl = props.href;
        }
        break;
      case 'title':
        seoTitle = children;
        break;
      case 'meta':
        if (props?.name === 'description') {
          seoDescription = props.content;
        } else if (props?.name === 'robots') {
          seoShowInSearch = !props.content?.includes('noindex');
          seoShowSnippetInSearch = !props.content?.includes('nosnippet');
        }
        break;
      default:
        break;
    }
  });

  return {
    canonicalUrl,
    seoTitle,
    seoDescription,
    seoShowInSearch,
    seoShowSnippetInSearch,
    seoSlug: post.slug,
  };
};

const isDemoUserPost = (
  post: RelatedPostV2 | PostV2 | PostV3,
  normalized: NormalizedPost,
): normalized is Required<NormalizedPost> => {
  const DEMO_USER_SITE_MEMBER_IDS = [
    '12345678-1234-1234-1234-123456789123',
    '68c56f39-fe67-4f3d-8ae7-991684463a63', // ru
    'eb619033-dfe7-43df-8bd5-c264da83aeb7', // ja
    '24536dc0-3451-4fa0-aaa1-e826b7828bfb', // es
    '537c64ba-b52b-4643-92fc-cc4e011b7a93', // de
    'f2923da0-3a70-4d26-8d80-b3227e9fe973', // fr
    '8a3e6d16-735e-4618-8be7-964643d23fc7', // pt
    'faf5a4cb-1fd4-4fbc-9e31-77a23ee976eb', // ko
  ];

  return (
    'owner' in post &&
    normalized.owner != null &&
    DEMO_USER_SITE_MEMBER_IDS.includes(post.owner?.siteMemberId as string)
  );
};

type GenericMetrics = {
  averageRating?: number;
  comments?: number;
  likes?: number;
  totalRatings?: number;
  views?: number;
};

type GenericPost = {
  id?: string;
};

export function enhancePostsWithMetadata<
  PostType extends GenericPost,
  MetricsType extends GenericMetrics,
>(posts: PostType[], postMetricsMap: Record<string, MetricsType>): PostType[] {
  const defaultMetrics = {
    averageRating: 0,
    comments: 0,
    likes: 0,
    totalRatings: 0,
    views: 0,
  };

  return posts.map((p) => ({
    ...p,
    metrics: (p.id && postMetricsMap[p.id]) || defaultMetrics,
  }));
}
