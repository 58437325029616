import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { omit } from 'lodash';
import { ConnectedProps } from 'react-redux';
import wrapDisplayName from 'recompose/wrapDisplayName';
import {
  getCurrentUser,
  isBlocked as getIsBlocked,
  isOwner as getIsOwner,
  isPrivate as getIsPrivate,
} from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type useAuth from '../hooks/use-auth';
import { isAuthenticated as getIsAuthenticated } from '../store/auth/auth-selectors';
import { isSite as getIsSite } from '../store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';

type ForPublicUserCallback = (
  fn: Function,
  options?: { preventDefault?: boolean },
) => any;

export type WithAuth = {
  currentUser: any;
  isAuthenticated: any;
  isBlocked: any;
  isOwner: any;
  isPrivate: any;
  forPublicUser: ForPublicUserCallback;
};

type Props = ConnectedProps<typeof connector>;

const NAME = 'withAuth';

/** @deprecated use {@link useAuth} hook instead */
function withAuth<T extends WithAuth = WithAuth>(
  WrappedComponent: React.ComponentType<T>,
) {
  class WithAuth extends Component<Props> {
    static displayName = wrapDisplayName(WrappedComponent, NAME);

    forPublicUser: ForPublicUserCallback = (fn, options) => {
      if (!this.props.__isMemberAreaActive || !this.props.__isSite) {
        return fn;
      }
      return this.decorateWithPrivacyChecker(fn, options);
    };

    decorateWithPrivacyChecker: ForPublicUserCallback = (
      fn,
      { preventDefault = false } = {},
    ) => {
      const isPrivate = () => this.props.isPrivate;
      const isAuthenticated = () => this.props.isAuthenticated;
      const showJoinCommunityPopup = () => this.props.showJoinCommunityPopup();

      return function (this: any, ...args: any[]) {
        if (preventDefault) {
          const event = args[0];
          event.preventDefault && event.preventDefault();
        }

        if (isAuthenticated() && isPrivate()) {
          showJoinCommunityPopup();
        } else {
          return fn.apply(this, args);
        }
      };
    };

    render() {
      const props = omit({ forPublicUser: this.forPublicUser, ...this.props }, [
        '__isMemberAreaActive',
        '__isSite',
        'showJoinCommunityPopup',
      ]);
      return <WrappedComponent {...(props as T)} />;
    }
  }

  hoistNonReactStatics(WithAuth, WrappedComponent);

  return connector(WithAuth);
}

const connector = connect((state, ownProps, actions) => {
  return {
    showJoinCommunityPopup: actions.showJoinCommunityPopup,
    currentUser: getCurrentUser(state),
    isAuthenticated: getIsAuthenticated(state),
    isBlocked: getIsBlocked(state),
    isOwner: getIsOwner(state),
    isPrivate: getIsPrivate(state),
    __isMemberAreaActive: getIsMemberAreaInstalled(state),
    __isSite: getIsSite(state),
  };
});

export default withAuth;
