import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import clamp from './clamp';

class DotDotDot extends Component {
  setContainerRef = (element) => (this.containerRef = element);

  componentDidMount() {
    if (this.props.clamp) {
      this.clampText();
    }
  }

  componentDidUpdate() {
    if (this.props.clamp) {
      this.clampText();
    }
  }

  clampText() {
    if (!this.props.clamp || !this.containerRef) {
      return;
    }

    if (this.containerRef.length) {
      throw new Error('Please provide exacly one child to dotdotdot');
    }

    clamp(this.containerRef, this.props);
  }

  render() {
    const { className, children, isTitle } = this.props;
    const Wrapper = isTitle ? 'p' : 'div';
    return (
      <Wrapper className={className} ref={this.setContainerRef}>
        {children}
      </Wrapper>
    );
  }
}

DotDotDot.propTypes = {
  children: PropTypes.node,
  clamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  truncationChar: PropTypes.string,
  useNativeClamp: PropTypes.bool,
  useExactLineHeight: PropTypes.bool,
  className: PropTypes.string,
  maxLineCount: PropTypes.number,
  isTitle: PropTypes.bool,
};

DotDotDot.defaultProps = {
  truncationChar: '\u2026',
  useNativeClamp: true,
  isTitle: false,
};

export default withResponsiveContext(DotDotDot);
