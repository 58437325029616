import { get, isEmpty } from 'lodash';
import { AppState } from '../../../types';

const getModalState = (state: AppState) => state.modal;

export const getResolvedModals = (state: AppState) =>
  getModalState(state).resolvedModalList;

export const getModal = (state: AppState, id: string) =>
  getModalState(state).openedModals[id];

export const getModalMeta = (state: AppState, id: string) =>
  get(getModal(state, id), 'meta', {});

export const getOpenedModals = (state: AppState) =>
  getModalState(state).openedModals;

export const isModalResolved = (state: AppState, id: string) =>
  Boolean(getResolvedModals(state).find((t) => t.type === id));

export const getIsModalDisplayed = (state: AppState, id: string) =>
  !isEmpty(id ? getOpenedModals(state) : getModal(state, id));
