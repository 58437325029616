import React from 'react';
import classNames from 'classnames';
import { range } from 'lodash';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { PAGE_NUMBERS_TO_SHOW, PaginationButtonType } from '../constants';
import type { PaginationState } from '../hooks/use-pagination-state';
import styles from '../pagination.scss';

type PageNumbersProps = {
  component: React.ComponentType<any>;
  innerClass?: string;
  activeClass?: string;
  state: PaginationState;
};

const PageNumbers: React.FC<PageNumbersProps> = ({
  state,
  component: Component,
  activeClass,
  innerClass,
}: PageNumbersProps) => {
  const { t } = useTranslation();
  const pageNumbers = getPageNumbers(state);

  return (
    <>
      {pageNumbers.map((renderedPage) => {
        const isActive = renderedPage === state.currentPage;
        const pageClassName = classNames(
          styles.page,
          innerClass,
          { 'blog-button-color': isActive },
          activeClass ? { [activeClass]: isActive } : undefined,
        );
        const eventHandlers = !isActive
          ? state.getButtonEventHandlers(
              renderedPage,
              PaginationButtonType.PAGE,
            )
          : undefined;

        return (
          <Component
            {...eventHandlers}
            key={renderedPage}
            page={renderedPage}
            isActive={isActive}
            className={pageClassName}
            aria-label={t('pagination.page', { page: renderedPage })}
            data-hook={`pagination__${renderedPage}`}
          >
            {renderedPage}
          </Component>
        );
      })}
    </>
  );
};

function getPageNumbers(state: PaginationState): number[] {
  const sideSize = Math.floor(PAGE_NUMBERS_TO_SHOW / 2);
  let from = 1;
  let to = PAGE_NUMBERS_TO_SHOW;

  if (state.lastPage < PAGE_NUMBERS_TO_SHOW) {
    to = state.lastPage;
  } else if (state.currentPage + sideSize + 1 > state.lastPage) {
    from = state.lastPage - sideSize * 2;
    to = state.lastPage;
  } else if (state.currentPage > sideSize + 1) {
    from = state.currentPage - sideSize;
    to = state.currentPage + sideSize;
  }

  return range(from, to + 1);
}

export default PageNumbers;
