import { ConnectedProps } from 'react-redux';
import { connect } from '../components/runtime-context';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type useDeviceType from '../hooks/use-device-type';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';

export type WithDeviceType = ConnectedProps<typeof connector>;

const connector = connect((state) => {
  const isMobile = getIsMobile(state);
  return {
    isMobile,
    isDesktop: !isMobile,
  };
});

/** @deprecated use {@link useDeviceType} hook instead */
function withDeviceType<T extends WithDeviceType = WithDeviceType>(
  WrappedComponent: React.ComponentType<T>,
) {
  return connector(WrappedComponent as React.ComponentType<WithDeviceType>);
}

export default withDeviceType;
