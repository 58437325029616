const throwMissingParam = (name: string) => {
  throw new Error(`Missing ${name} param in selector`);
};

/**
 * @deprecated Does not work well with Typescript. It's better to define
 * the selector arguments inline:
 *
 * @example
 * const getPublishedPosts = createSelector(
 *   [
 *     getAllPosts,
 *     (_, status: Status) => status
 *   ]
 * )
 **/
// @ts-expect-error
export const secondParam = (_1, _2) => _2 || throwMissingParam('second');

/**
 * @deprecated Does not work well with Typescript. It's better to define
 * the selector arguments inline:
 *
 * @example
 * const getPublishedPosts = createSelector(
 *   [
 *     getAllPosts,
 *     getCurrentUser,
 *     (_, status: Status) => status
 *   ]
 * )
 **/
// @ts-expect-error
export const thirdParam = (_1, _2, _3) => _3 || throwMissingParam('third');
